import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55c81d52"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onMousedown", "onMouseup"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(['pointer-events: none;', $setup.getMarginContainerStyle]),
    class: "margin-container"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['main-container', { 'active-page': $setup.MainStore.activePage == $props.page }]),
      ref: $setup.setElements($props.page, $props.page.index),
      style: _normalizeStyle([$setup.MainStore.getPageStyle, 'pointer-events: auto;']),
      onMousedown: _withModifiers($setup.handleMouseDown, ["left"]),
      onMousemove: $setup.handleMouseMove,
      onMouseleave: $setup.handleMouseLeave,
      onMouseup: _withModifiers($setup.handleMouseUp, ["left"]),
      onClick: _cache[0] || (_cache[0] = 
				(e) => {
					$setup.MainStore.activePage = $setup.getParentPage(e.currentTarget.piniaElementRef);
				}
			)
    }, [
      ($props.page.DOMRef)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($props.page.header, (object, index) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(
						object.type == 'text'
							? $setup.isComponent[object.type][object.isDynamic ? 'dynamic' : 'static']
							: $setup.isComponent[object.type]
					), _normalizeProps(_mergeProps({
              key: object.id
            }, { object, index })), null, 16 /* FULL_PROPS */))
          }), 128 /* KEYED_FRAGMENT */))
        : _createCommentVNode("v-if", true),
      ($props.page.DOMRef)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($props.page.childrens, (object, index) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(
						object.type == 'text'
							? $setup.isComponent[object.type][object.isDynamic ? 'dynamic' : 'static']
							: $setup.isComponent[object.type]
					), _normalizeProps(_mergeProps({
              key: object.id
            }, { object, index })), null, 16 /* FULL_PROPS */))
          }), 128 /* KEYED_FRAGMENT */))
        : _createCommentVNode("v-if", true),
      ($props.page.DOMRef)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList($props.page.footer, (object, index) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(
						object.type == 'text'
							? $setup.isComponent[object.type][object.isDynamic ? 'dynamic' : 'static']
							: $setup.isComponent[object.type]
					), _normalizeProps(_mergeProps({
              key: object.id
            }, { object, index })), null, 16 /* FULL_PROPS */))
          }), 128 /* KEYED_FRAGMENT */))
        : _createCommentVNode("v-if", true)
    ], 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_1)
  ], 4 /* STYLE */))
}